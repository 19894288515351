@tailwind base;
@tailwind components;
@tailwind utilities;

.input {
  @apply w-full pl-2 pb-2 outline-none text-xs text-left focus:outline-none border-b md:mx-0 placeholder-gray-300 md:placeholder-gray-700;
}

.red-btn {
  @apply bg-gradient-to-r from-red-700 to-red-800 flex justify-center items-center hover:from-red-600 hover:to-red-700 text-white tracking-wider rounded;
}

.volas-logo-sides {
  @apply transform -rotate-90 md:rotate-0 w-12 md:w-1/4 md:h-1/4;
}

.yellow-pattern {
  @apply w-60 md:w-72 mt-8;
}

.acct-input-container {
  @apply flex w-full justify-center md:justify-start md:w-1/3 my-1 md:my-0;
}

.acct-input-pw-container {
  @apply flex w-full justify-center md:w-1/3 md:border-b my-1 md:my-0 md:border-black;
}

.exp-btn {
  @apply text-xs md:text-sm cursor-pointer w-auto rounded-2xl self-start hover:text-volas-darker-red hover:bg-volas-light-red md:py-1 px-3;
}

.exp-btn-selected {
  @apply bg-volas-light-red ring-1 ring-volas-darker-red text-volas-darker-red;
}

.exp-btn-non-selected {
  @apply bg-transparent ring-1 ring-xx-neutral-gray text-xx-neutral-gray;
}

.experience-header {
  @apply flex md:mb-2 mb-4 font-semibold tracking-wider text-xxs md:text-base w-full md:w-auto;
}

.roles-div {
  @apply flex md:flex-col w-auto px-1 md:px-0 py-1 mb-1 md:mb-0;
}

.roles-container {
  @apply w-full flex flex-wrap md:block justify-start md:w-auto  mr-4 mb-6 md:mb-0;
}

.onboard-info {
  @apply text-3xl lg:text-4xl font-extralight md:font-normal -mb-4;
}
.mobile-steps {
  @apply mb-3 text-xxs md:text-sm-xx md:font-semibold md:text-gray-300 text-gray-400 mt-10 tracking-widest;
}

.onboard-header {
  @apply hidden md:block text-3xl md:text-4xl lg:text-5xl mt-16 md:mt-2 mb-2;
}

.onboard-header-3 {
  @apply hidden md:block text-xs md:text-base lg:text-xl;
}

.nav-menu-img {
  @apply mx-10 w-10 pb-1 md:pb-0 md:w-12 self-center;
}

.nav-menu-text {
  @apply text-white tracking-wider md:tracking-extra text-xxxs md:text-xxs;
}

.nav-menu-container {
  @apply flex w-auto md:w-full mt-3 md:mt-0 h-auto md:h-12 self-center flex-col items-center md:flex-row justify-center md:justify-start cursor-pointer;
}

.nav-menu-divider {
  @apply hidden md:block w-full border-b border-gray-600;
}

.loader {
  @apply modal w-32 z-50;
}

.permission-role {
  @apply w-full border-b border-gray-300;
}

.tx-divider {
  @apply w-full border-b border-x-neutral-gray my-5 max-w-865;
}

.minimum-height-20 {
  min-height: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none !important;
}

.no-scrollbar {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

body {
  overflow-x: hidden !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(0px);
}

.center-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-height: 520px) {
  .logo-decor {
    display: none;
  }
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #545456 #efefef;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #efefef;
}

*::-webkit-scrollbar-thumb {
  background-color: #545456;
  border-radius: 20px;
  border: 3px solid #efefef;
}

/*.no-border > *, .no-border, .no-border > input:focus, .no-border > select:focus {*/
/*  border: none !important;*/
/*  outline: none !important;*/
/*  box-shadow: none !important;*/
/*}*/

/*.no-border > .css-26l3qy-menu, .no-border .css-26l3qy-menu, .css-26l3qy-menu {*/
/*  border: 1px solid #9ca3af !important;*/
/*}*/
